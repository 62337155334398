<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ lectureID ? sprintf($t('pages.module.lecture.save.editLecture'), [lectureTranslate.title ?? '', productTranslate.title ?? '']) : sprintf($t('pages.module.lecture.save.newLecture'), [productTranslate.title ?? '']) }}
        </h2>
        <router-link :to="'/module/lecture?productID=' + this.productID" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.module.lecture.subTitle") }}
        </router-link>
    </div>
    <el-tabs v-model="tabModel" class="lecture-tabs">
        <el-tab-pane :label="$t('pages.module.lecture.save.tabs.generalDetails')" name="general">
            <el-form :model="form" ref="lectureForm">
                <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.save.cols.title') }}</label>
                                    <el-form-item prop="translate.title" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.translate.title" type="text"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.save.cols.description') }}</label>
                                    <el-form-item prop="translate.description">
                                        <CustomCkeditor :model="form.translate.description"  @updateModel="form.translate.description = $event"></CustomCkeditor>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.save.cols.content') }}</label>
                                    <el-form-item prop="translate.content">
                                        <CustomCkeditor :model="form.translate.content"  @updateModel="form.translate.content = $event"></CustomCkeditor>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.save.cols.sort') }}</label>
                                    <el-form-item prop="data.sort" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.sort" :min="1"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.lecture.save.cols.status') }}</label>
                                    <el-form-item prop="data.active">
                                        <el-radio-group v-model="form.data.active">
                                            <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mt-5">
                                    <el-form-item class="mb-0">
                                        <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                            <span v-if="form.loading" class="indicator-progress">
                                                {{ $t("messages.wait") }}
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus && supportedStreamVariants.length" :label="$t('pages.module.lecture.save.tabs.streamSettings')" name="streamSettings">
            <Stream :lectureID="lectureID" v-if="tabModel == 'streamSettings'" :supportedStreamVariants="supportedStreamVariants"></Stream>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.module.lecture.save.tabs.documentSettings')" name="documentSettings">
            <Document documentableType="LECTURE" :documentableID="lectureID" v-if="tabModel == 'documentSettings'"></Document>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import Stream from "@/components/module/lecture/Stream";
import CustomCkeditor from "@/components/custom-ckeditor"
import Document from "@/components/module/document";

export default {
    name: "_id",
    components: {
        CustomCkeditor,
        Document,
        Stream
    },
    data() {
        return {
            tabModel: 'general',
            form: {
                updateStatus: false,
                loading: false,
                translate: {
                    language_id: this.$root.defaultLanguage.id,
                },
                data: {
                    sort: 1,
                    active: true
                },
            },
            product: {},
            productID: undefined,
            lecture: {},
        }
    },
    computed: {
        lectureID() {
            return this.$route.params.id;
        },
        pageShow() {
            if (this.lectureID && !(this.lectureID > 0)) {
                return false;
            }

            if (!this.productID || !(this.productID > 0)) {
                return false;
            }

            return true;
        },
        lectureTranslate() {
            return this.resolveDatum(this.lecture.translations, this.$root.defaultLanguage.id, 'language_id');
        },
        productTranslate() {
            return this.resolveDatum(this.product.translations, this.$root.defaultLanguage.id, 'language_id');
        },
        recordLiveVariant() {
            return this.$store.state.ecommerce.productManagement.variant.item.table.data;
        },
        supportedStreamVariants() {
            let supportedStreamVariants = [];

            if (this.recordLiveVariant.length && this.product.variant_combinations && this.product.variant_combinations.length) {
                let streamVariants = this.recordLiveVariant.map(item => item.code);
                for (let combinationIndex in this.product.variant_combinations) {
                    let combination = this.product.variant_combinations[combinationIndex];
                    for (let itemIndex in combination.variants) {
                        let variant = combination.variants[itemIndex];
                        if (streamVariants.includes(variant.item.code)) {
                            supportedStreamVariants.push(variant.item.code);

                            if (streamVariants.length == supportedStreamVariants.length) {
                                return supportedStreamVariants;
                            }
                        }
                    }
                }
            }

            return supportedStreamVariants;
        }
    },
    created() {
        this.productID = this.$route.query.productID;

        if (!this.pageShow) {
            this.$router.push({
                path: "/module/lecture"
            });
        }

        this.productID = this.productID ? parseInt(this.productID) : undefined;
    },
    mounted() {
        if (this.productID && this.productID > 0) {
            this.loadProduct();
        }
        if (this.lectureID && this.lectureID > 0) {
            this.loadLecture();
        }
    },
    methods: {
        onSubmit() {
            this.$refs.lectureForm.validate((valid) => {
                if (valid) {
                    let formData = this.prepareLectureFormData();

                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['module_lecture'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['module_lecture'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.$router.push({
                                    path: "/module/lecture/save/" + response.data.data.id,
                                    query: {productID: this.productID}
                                });
                                this.loadLecture(response.data.data.id);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        loadLecture(lectureID = this.lectureID) {
            this.axios.get(this.endpoints['module_lecture'] + '/' + lectureID).then((response) => {
                let data = response.data.data;
                this.lecture = data;

                this.form.translate = this.lectureTranslate;
                this.form.updateStatus = true;
                this.form.data = data;
            })
        },
        loadProduct(productID = this.productID) {
            this.axios.get(this.endpoints['ecommerce_product'] + '/' + productID).then((response) => {
                let data = response.data.data;
                this.product = data;
                this.loadLiveRecordVariant();
            })
        },
        prepareLectureFormData() {
            let formData = this.form.data;

            formData.product_id = this.productID;

            return {...this.form.translate, ...formData};
        },
        loadLiveRecordVariant() {
            this.$store.dispatch("ecommerce/productManagement/variant/group/get", {
                page: {},
                filterData: {
                    code: "LIVE_OR_RECORD"
                }
            }).then((data) => {
                if (data.length) {
                    this.$store.dispatch('ecommerce/productManagement/variant/item/get', {
                        page: {},
                        filterData: {
                            group_id: data[0].id
                        }
                    });
                }
            });
        }
    }
}
</script>

<style>
</style>